class LogoResizer {
  constructor() {
    this.footer = document.querySelector(".footer")
    this.parent = document.querySelector(".footer__sticky")
    this.logo = document.querySelector(".footer__sticky").childNodes[1]

    console.log("Referrer:", document.referrer)
    console.log("Current Path:", window.location.pathname)

    if (!document.referrer && window.location.pathname !== "/") {
      console.log("A")
      this.resizeLargeToSmall()
    } else if (!document.referrer) {
      console.log("B")
      return
    } else if (document.referrer === window.location.origin + "/" && window.location.pathname === "/") {
      console.log("C")
      return
    } else if (document.referrer === window.location.origin + "/") {
      console.log("D")
      this.parent.classList.add("footer__sticky--transition")
      this.resizeLargeToSmall()
    } else if (document.referrer !== window.location.origin + "/" && window.location.pathname === "/") {
      console.log("E")
      this.resizeLargeToSmallToLarge()
    } else if (document.referrer != window.location.origin + "/") {
      console.log("F")
      this.resizeLargeToSmall()
    }
  }

  resizeLargeToSmall() {
    this.logo.style.width = "170px"
    this.logo.style.height = "26px"
    this.footer.style.marginBottom = "6rem"
  }

  resizeLargeToSmallToLarge() {
    this.logo.style.width = "170px"
    this.logo.style.height = "26px"
    setTimeout(() => {
      this.parent.classList.add("footer__sticky--transition")
      this.logo.style.width = "600px"
      this.logo.style.height = "93.5px"
    }, 0)
  }
}

export default LogoResizer
