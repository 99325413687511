class FadingTextHandler {
  constructor() {
    this.container = document.querySelector(".fading_text")

    this.textCollection = Array.from(this.container.children).filter(child => ["P", "H3"].includes(child.tagName))

    this.count = 0

    if (this.textCollection.length > 0) {
      this.textCollection[0].style.opacity = 1

      if (this.textCollection.length > 1) {
        setInterval(() => this.showNextText(), 3000)
      } else if (this.textCollection.length == 1) {
        this.textCollection[0].classList.add("onetext")
      }
    }
  }

  showNextText() {
    this.textCollection[this.count].style.opacity = 0

    this.count = (this.count + 1) % this.textCollection.length

    this.textCollection[this.count].style.opacity = 1
  }
}

export default FadingTextHandler
