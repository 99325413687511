import FadingTextHandler from "./modules/fadingTextHandler"
import LogoResizer from "./modules/logoResizer"
import NavHandler from "./modules/navHandler"

document.addEventListener("DOMContentLoaded", () => {
  // Add all your JS modules here:
  if (document.querySelector(".fading_text")) {
    let fadingTextHandler = new FadingTextHandler()
  }
  let logoResizer = new LogoResizer()
  let navHandler = new NavHandler()
})
