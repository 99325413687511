class NavHandler {
  constructor() {
    this.button = document.querySelector(".burger")
    this.header = document.querySelector(".header")
    this.headerInner = document.querySelector(".header__inner")
    this.headerNav = document.querySelector(".header__nav")

    this.button.addEventListener("click", ev => {
      if (ev.target.classList.contains("burger--open")) {
        ev.target.classList.remove("burger--open")

        this.header.classList.remove("header--open")
        this.headerInner.classList.remove("header__inner--open")
        this.headerNav.classList.remove("header__nav--open")
      } else {
        ev.target.classList.add("burger--open")
        this.header.classList.add("header--open")
        this.headerInner.classList.add("header__inner--open")
        this.headerNav.classList.add("header__nav--open")
      }
    })

    document.addEventListener("click", ev => {
      let navOpen = document.querySelector(".header--open")
      let insideMenu = this.header.contains(ev.target)

      console.log("A")

      if (navOpen && !insideMenu) {
        this.button.classList.remove("burger--open")

        this.header.classList.remove("header--open")
        this.headerInner.classList.remove("header__inner--open")
        this.headerNav.classList.remove("header__nav--open")
      }
    })
  }
}

export default NavHandler
